<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Crie combos de produtos que aplicam desconto e/ou brinde para determinado item. Ideal para realização de promoções que envolvem vários produtos e condições.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-sm-6 col-md-4 mb-3">
							<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
							<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
								<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.loja">
								<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
							</select>
                  </div>
                  <div class="col-sm-6 col-md-4 col-xxl-6 mb-3">
							<label><i class="far fa-shopping-bag color-theme font-11 me-1"></i> Nome</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarCombos" placeholder="Busque por nome do combo/pack" />
                  </div>
						<div class="col-md-4 col-xxl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarCombos"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Editar combos (packs)')">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                        </div>
                     </div>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<combo v-for="(combo, index) in pesquisa.retorno.resultado" :key="index" :combo="combo" :index="index" @editar="abrirModal($event)" />
					</div>
				</div>
			</div>
      </div>

		<!-- modalCombo -->
      <div class="modal fade" id="modalCombo" tabindex="-1" aria-labelledby="modalComboLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalComboLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} combo/pack {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-produto-tab" data-bs-toggle="pill" data-bs-target="#pills-produto" type="button" role="tab" aria-controls="pills-produto" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-lg-inline"> Principal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-loja-tab" data-bs-toggle="pill" data-bs-target="#pills-lojas" type="button" role="tab" aria-controls="pills-lojas" aria-selected="false">
											<i class="far fa-store font-12"></i><span class="ms-1 d-none d-lg-inline"> Lojas</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-produtos-tab" data-bs-toggle="pill" data-bs-target="#pills-produtos" type="button" role="tab" aria-controls="pills-produtos" aria-selected="false">
											<i class="far fa-carrot font-12"></i><span class="ms-1 d-none d-lg-inline"> Itens necessários</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-brinde-tab" data-bs-toggle="pill" data-bs-target="#pills-brinde" type="button" role="tab" aria-controls="pills-brinde" aria-selected="false">
											<i class="far fa-percentage font-13"></i><span class="ms-1 d-none d-lg-inline"> Brinde</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-produto" role="tabpanel" aria-labelledby="pills-produto-tab">
								<div class="card mb-2">
									<div class="card-body p-12">
										<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Nome *</label>
										<input type="text" class="form-control" v-model="modal.nome" maxlength="200" />
										<div class="alert alert-secondary alert-dismissible fade show shadow-none mb-0 pe-3 mt-2" role="alert">
											<strong>Brindes</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
											<span>Defina os itens necessários para que o brinde seja aplicado. Exemplo: A cada 2 pizzas o cliente ganha 1 refrigerante por R$0,01.</span>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-lojas" role="tabpanel" aria-labelledby="pills-lojas-tab">
								<div class="row">
									<loja v-for="(loja, index) in modal.lojas" :key="index" :index="index" :loja="loja" class="px-0"/>

									<div class="col-12 my-5 text-center" v-if="modal.lojas.length == 0">
										<i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
										<p class="font-13 mt-4 mb-0 text-secondary">Nenhuma loja encontrada</p>
                              <h4 class="font-12 mt-2 mb-0 text-secondary text-uppercase">Solicite permissão ou utilize a tela "Lojas" para adicionar</h4>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-produtos" role="tabpanel" aria-labelledby="pills-produtos-tab">
								<div class="card">
									<div class="card-body p-12">
										<div class="col-12 mb-3">
											<h6 class="mb-0 mt-1 font-15">
												<span class="mb-0 font-14 weight-400">{{ modal.produtos.length }} produto(s) encontrado(s)</span>
												<a href="javascript:;" class="float-end font-16" @click="toggleAddProduto('produto')"><i class="fas fa-plus color-theme"></i></a>
											</h6>
										</div>
										
										<produto v-for="(produto, index) in modal.produtos" :key="index" :produto="produto" :index="index" :campos="{'quantidade': true, 'excluir': true}" 
											@remover="removerProduto($event, 'produto')" />
										
										<div class="col-12 my-5 text-center" v-if="modal.produtos.length == 0">
											<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
											<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-brinde" role="tabpanel" aria-labelledby="pills-brinde-tab">
								<div class="card">
									<div class="card-body p-12">
										<div class="col-12 mb-3">
											<h6 class="mb-0 mt-1 font-15">
												<span class="mb-0 font-14 weight-400">{{ modal.brinde ? 1 : 0 }} brinde encontrado</span>
												<a href="javascript:;" class="float-end font-16" @click="toggleAddProduto('brinde')"><i class="fas fa-plus color-theme"></i></a>
											</h6>
										</div>

										<div class="row" v-if="modal.brinde">
											<produto :produto="modal.brinde" :campos="{'quantidade': true, 'preco': true, 'selectEAN': true, 'excluir': true}" @remover="removerProduto($event, 'brinde')" />
										</div>
										
										<div class="col-12 my-5 text-center" v-else>
											<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
											<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarCombo">Salvar</button>
						<button type="button" class="btn btn-outline-primary" v-if="modal.id" @click="imprimir">Imprimir</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>

		<modalProdutoPreco :modalAnterior="'#modalCombo'" @addProduto="addProduto($event)" />

		<impressao id="impressao" class="d-none" :titulo="$route.name.replace(/_/g, ' ').replace(/-/g, '/')" :data="new Date()" :cabecalho="{
			'Nome combo': modal.nome == null ? ' - ' : modal.nome, 
			'Lojas': String(modal.lojas.filter(l => l.habilitado).map(l => l.nomeFantasia)).replace(/,/g, ', '),
			'Brinde - Produto': modal.brinde == null ? ' - ' : modal.brinde.nome, 
			'Brinde - Quantidade': modal.brinde == null ? ' - ' : parseFloat(modal.brinde.quantidade).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:4}), 
			'Brinde - Valor': modal.brinde == null ? ' - ' : parseFloat(modal.brinde.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:4})
			}" :itensKey="{'nome': 'Descrição', 'quantidade': 'Quantidade'}" :itens="modal.produtos" />
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import combo from '@/components/modelos/Combo.vue'
import loja from '@/components/modelos/LojaCheck.vue'
import produto from '@/components/modelos/Produto2.vue'
import impressao from '@/components/impressao/ComprovanteA4.vue'
import modalProdutoPreco from '@/components/modelos/ModalProdutoPreco.vue'

export default {
	name: 'CombosPacks',
	data: function () {
		return {
			pesquisa: {'loja': null, 'nome': '', 'isVasilhame': false, 'retorno': {'loja': null, 'nome': null, 'resultado': []}},
			modal: {'id': null, 'nome': null, 'isVasilhame': false, 'lojas': [], 'brinde': null, 'produtos': [], 'produto': {'toggle': false, 'tipo': null}}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		combo, loja, produto, impressao, modalProdutoPreco
	},
	methods: {
		imprimir : function () {
         setTimeout(() => this.$htmlToPaper('impressao', {styles: ['./comprovanteA4.css']}), 250);
      },
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Combos',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'nome', 'nomeBrinde'], 
				'lista': {'nome': 'lojas', 'campos': ['numeroLoja', 'nomeFantasia']}
			})
      },
		abrirModal : function (combo) {
			this.modal = {
				'id': null, 'nome': null, 'isVasilhame': false, 'lojas': JSON.parse(JSON.stringify(this.dadosUsuario.usuarioLojas)), 
				'brinde': null, 'produtos': [], 'produto': {'toggle': false, 'tipo': null}
			}

			if (combo != null) {
				this.buscarCombo(combo)
			} else {
				$('#modalCombo').modal('show')
			}
		},
		buscarCombos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/searchCombos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarCombo : function (combo) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'automacao/getCombo',
				params: {
					id: combo.id
				}
			}).then(response => {
				$('#modalCombo').modal('show')

				this.modal = {
					'id': response.data.id, 
					'nome': response.data.nome, 
					'isVasilhame': response.data.isVasilhame,
					'lojas': JSON.parse(JSON.stringify(response.data.lojas)), 
					'brinde': response.data.brinde,
					'produtos': JSON.parse(JSON.stringify(response.data.produtos)),
					'produto': {'toggle': false, 'tipo': null}
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		toggleAddProduto : function (tipo) {
			this.modal.produto = {'toggle': !this.modal.produto.toggle, 'tipo': tipo}
			$('#modalCombo').modal('hide')
			$('#modalProdutoPreco').modal('show')
		},
		addProduto : function (produto) {
			if (this.modal.produto.tipo == 'brinde') {
				this.modal.brinde = JSON.parse(JSON.stringify(produto))
			} else {
				this.modal.produtos.push(JSON.parse(JSON.stringify(produto)))
			}

			this.modal.produto = {'toggle': false, 'tipo': null}
		},
		removerProduto : function (index, tipo) {
			if (tipo == 'brinde') {
				this.modal.brinde = null
			} else {
				this.modal.produtos.splice(index, 1)
			}
		},
		salvarCombo : function () {
			if (this.modal.nome == null || String(this.modal.nome).trim().length == 0 || this.modal.brinde == null || this.modal.produtos.length == 0 || 
				this.modal.lojas.findIndex(loja => loja.habilitado) == -1) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			} else if (this.modal.produtos.filter(p => !/^[0-9.]+$/.test(p.quantidade) || parseFloat(p.quantidade) < 1).length > 0 ||
				!/^[0-9.]+$/.test(this.modal.brinde.quantidade) || !/^[0-9.]+$/.test(this.modal.brinde.preco) || 
				parseFloat(this.modal.brinde.quantidade) < 1 || parseFloat(this.modal.brinde.preco) < 0.01) {
				this.$toast.fire({
					icon: 'error',
					title: 'Existem produtos com quantidade/preços inválidos!'
				});
	
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/saveCombo',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalCombo').modal('hide')
				this.buscarCombos()

				this.$toast.fire({
					icon: 'success',
					title: 'Combo/Pack salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>